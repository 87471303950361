import React from "react"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import { Link } from "gatsby"
import moment from "moment"

import "../styles/main.scss"
// import "bootstrap-navbar-sidebar/dist/navbar-fixed-left.css"
function Menu() {
  return (
    <Navbar bg={null} expand={false} className="sticky-top display-4 p-0">
      <Container className="justify-content-start pt-2 pb-1">
        <Link to="/" className="bas p-0">
          <mark className="firstname">Bas</mark> 
        </Link>
        <Link to="/en/research" activeClassName="active">
          <span className="mark white">research</span>
        </Link>
        <Link to="/en/design" activeClassName="active">
          <span className="mark white">design</span>
        </Link>
        <Link to="/en/music" activeClassName="active">
          <span className="mark white">music</span>
        </Link>
      </Container>
    </Navbar>
  )
}
export default function Layout({ children, ...props }) {
  return (
    <div {...props}>
      <div style={{height: "20vh"}}></div>
      <Menu />
      <Container style={{marginBottom: "10rem"}}>
        <p className="lastname display-4"><mark>Cornelissen</mark></p>
      </Container>
      {children}
      <Container className="site-footer py-5 small text-muted">
        Copyright &copy; Bas Cornelissen 2010–{moment().format("YYYY")}
      </Container>
    </div>
  )
}
